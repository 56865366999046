const actions = {
  SET_STATE: 'rfm/SET_STATE',
  GET_DATA: 'rfm/GET_DATA',

  SET_CURRENT_PAGE: 'rfm/SET_CURRENT_PAGE',
  SET_SUB_PAGE: 'rfm/SET_SUB_PAGE',
  CHANGE_TABLE_SETTING: 'rfm/CHANGE_TABLE_SETTING',

  GET_RECENCY_FREQUENCY: 'rfm/GET_RECENCY_FREQUENCY',
  GET_MIGRATION: 'rfm/GET_MIGRATION',

  GET_ACTION_TABLE_DATA: 'rfm/GET_ACTION_TABLE_DATA',
  GET_ACTION_TABLE_DATA_STATS: 'rfm/GET_ACTION_TABLE_DATA_STATS',
  CHANGE_ACTION_TABLE_SETTING: 'rfm/CHANGE_ACTION_TABLE_SETTING',
  CHANGE_CLASSIFICATION: 'rfm/CHANGE_CLASSIFICATION',
  CHANGE_ACTION_TABLE_CLASSIFICATION: 'rfm/CHANGE_ACTION_TABLE_CLASSIFICATION',
  SET_ACTION_TABLE_STATE: 'rfm/SET_ACTION_TABLE_STATE',
}

export default actions
