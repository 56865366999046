import actions from './actions'

const initialState = {
  users: [],
  loading: false,
  accounts: [],
  selectedAccount: [],
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_STATE_ACCOUNTS:
      state.accounts.push(action.payload.account)
      return state
    default:
      return state
  }
}
