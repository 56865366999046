const actions = {
  GET_DROPDOWN_VALUES: 'GET_DROPDOWN_VALUES',
  SET_DROPDOWN_VALUES: 'SET_DROPDOWN_VALUES',
  SET_STATE: 'cocAccountValidation/SET_STATE',
  GET_DATA: 'cocAccountValidation/GET_DATA',
  GET_COUNTER: 'cocAccountValidation/GET_COUNTER',
  GET_INPUT_STATUSES: 'cocAccountValidation/GET_INPUT_STATUSES',
  GET_UPLOAD_DETAILS: 'cocAccountValidation/GET_UPLOAD_DETAILS',
  GET_CAN_RUN: 'cocAccountValidation/GET_CAN_RUN',
  GET_RESULTS: 'cocAccountValidation/GET_RESULTS',
  GET_SUMMARY: 'cocAccountValidation/GET_SUMMARY',
  GET_TEXTSUMMARY: 'cocAccountValidation/GET_TEXTSUMMARY',
  GET_MARKETDETAILS: 'cocAccountValidation/GET_MARKETDETAILS',
  GET_HEATMAP: 'cocAccountValidation/GET_HEATMAP',
  GET_PROVINCEMAP: 'cocAccountValidation/GET_PROVINCEMAP',
  GET_MARKETCOVERAGE: 'cocAccountValidation/GET_MARKETCOVERAGE',
  GET_ACCOUNTDETAILS: 'cocAccountValidation/GET_ACCOUNTDETAILS',
  CHANGE_SETTING: 'cocAccountValidation/CHANGE_SETTING',
  SET_STATE_SETTINGS: 'cocAccountValidation/SET_STATE_SETTINGS',
  GET_TABLEDATA_STATS: 'cocAccountValidation/GET_TABLEDATA_STATS',
  GET_CLEANED_DETAILS: 'cocAccountValidation/GET_CLEANED_DETAILS',
  GET_CREDIT_DETAILS: 'cocAccountValidation/GET_CREDIT_DETAILS',
  SET_CREDIT_DETAILS: 'cocAccountValidation/SET_CREDIT_DETAILS',
  BOUGHT_CREDIT_DETAILS: 'cocAccountValidation/BOUGHT_CREDIT_DETAILS',
  GET_MARKETBAR: 'cocAccountValidation/GET_MARKETBAR',
  GET_MARKETPIE: 'cocAccountValidation/GET_MARKETPIE',
}

export default actions
