import actions from './actions'

const initialState = {
  productData: [],
  loading: false,
  rfmAllowed: false,
  rfmResults: [],
  accountProducts: [],
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_STATE_ACCOUNTPRODUCTS: {
      const idx = state.accountProducts.findIndex(o => o.id === action.payload.accountProducts.id)
      state.accountProducts[idx] = action.payload.accountProducts
      return state
    }
    case actions.ADD_STATE_ACCOUNTPRODUCTS:
      state.accountProducts.push(action.payload.accountProducts)
      return state
    case actions.UPDATE_DELETE_ACCOUNTPRODUCTS: {
      const index = state.accountProducts.findIndex(n => n.id === action.payload.id)
      state.accountProducts.splice(index, 1)
      return state
    }
    default:
      return state
  }
}
