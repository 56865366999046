const actions = {
  SET_STATE: 'lq/SET_STATE',
  SET_STATE_SETTINGS: 'lq/SET_STATE_SETTINGS',
  CHANGE_SETTING: 'lq/CHANGE_SETTING',

  UPDATE_STATE_COMPANY: 'lq/UPDATE_STATE_COMPANY',
  UPDATE_STATE_COMPANY_FOLLOWUP: 'lq/UPDATE_STATE_COMPANY_FOLLOWUP',
  UPDATE_STATE_ACTION: 'lq/UPDATE_STATE_ACTION',
  UPDATE_STATE_ACTION_FOLLOWUP: 'lq/UPDATE_STATE_ACTION_FOLLOWUP',
  UPDATE_STATE_NOTE: 'lq/UPDATE_STATE_NOTE',
  UPDATE_STATE_NOTE_FOLLOWUP: 'lq/UPDATE_STATE_NOTE_FOLLOWUP',
  UPDATE_STATE_CLASSIFICATION: 'lq/UPDATE_STATE_CLASSIFICATION',
  UPDATE_STATE_CLASSIFICATION_FOLLOWUP: 'lq/UPDATE_STATE_CLASSIFICATION_FOLLOWUP',
  UPDATE_STATE_ADDITIONAL: 'lq/UPDATE_STATE_ADDITIONAL',
  UPDATE_STATE_ADDITIONAL_FOLLOWUP: 'lq/UPDATE_STATE_ADDITIONAL_FOLLOWUP',
  UPDATE_STATE_CONTACTPERSON: 'lq/UPDATE_STATE_CONTACTPERSON',
  UPDATE_STATE_CONTACTPERSON_FOLLOWUP: 'lq/UPDATE_STATE_CONTACTPERSON_FOLLOWUP',

  UPDATE_DELETE_STATE_NOTE: 'lq/UPDATE_DELETE_STATE_NOTE',
  UPDATE_DELETE_STATE_NOTE_FOLLOWUP: 'lq/UPDATE_DELETE_STATE_NOTE_FOLLOWUP',
  UPDATE_DELETE_STATE_ACTIVITY: 'lq/UPDATE_DELETE_STATE_ACTIVITY',
  UPDATE_DELETE_STATE_ACTIVITY_FOLLOWUP: 'lq/UPDATE_DELETE_STATE_ACTIVITY_FOLLOWUP',
  UPDATE_DELETE_STATE_ADDITIONAL: 'lq/UPDATE_DELETE_STATE_ADDITIONAL',
  UPDATE_DELETE_STATE_ADDITIONAL_FOLLOWUP: 'lq/UPDATE_DELETE_STATE_ADDITIONAL_FOLLOWUP',

  SET_STATE_CONTACTPERSON: 'lq/SET_STATE_CONTACTPERSON',
  SET_STATE_CONTACTPERSON_FOLLOWUP: 'lq/SET_STATE_CONTACTPERSON_FOLLOWUP',

  GET_TABLEDATA: 'lq/GET_TABLEDATA',
  GET_FUNNEL_DATA: 'lq/GET_FUNNEL_DATA',
  GET_COMPANY_CLASSIFICATION: 'lq/GET_COMPANY_CLASSIFICATION',
  GET_TABLEDATA_STATS: 'lq/GET_TABLEDATA_STATS',
  EDIT_COMPANY: 'lq/EDIT_COMPANY',
  CLASSIFY_LEAD: 'lq/CLASSIFY_LEAD',
  CLASSIFY_FOLLOW_UP_TASK: 'lq/CLASSIFY_FOLLOW_UP_TASK',
  CREATE_NOTE: 'lq/CREATE_NOTE',
  DELETE_NOTE: 'lq/DELETE_NOTE',
  CREATE_ACTIVITY: 'lq/CREATE_ACTIVITY',
  DELETE_ACTIVITY: 'lq/DELETE_ACTIVITY',
  CREATE_CONTACTPERSON: 'lq/CREATE_CONTACTPERSON',
  EDIT_CONTACTPERSON: 'lq/EDIT_CONTACTPERSON',
  SET_LEADSTOFETCH: 'lq/SET_LEADSTOFETCH',
  CREATE_ADDITIONAL_DATA: 'lq/CREATE_ADDITIONAL_DATA',
  DELETE_ADDITIONAL: 'lq/DELETE_ADDITIONAL',
  DELETE_CONTACTPERSON: 'lq/DELETE_CONTACTPERSON',

  GET_ACTIVITIES_PER_RESULT_TYPE: 'lq/GET_ACTIVITIES_PER_RESULT_TYPE',
  GET_ACTIVITIES_PER_RESULT_TYPE_USER: 'lq/GET_ACTIVITIES_PER_RESULT_TYPE_USER',
  GET_LEADS_CLASSIFIED: 'lq/GET_LEADS_CLASSIFIED',
  GET_LEADS_CLASSIFIED_USER: 'lq/GET_LEADS_CLASSIFIED_USER',
  GET_LEADS_WITH_ACTIVITY: 'lq/GET_LEADS_WITH_ACTIVITY',
  GET_LEADS_WITH_ACTIVITY_USER: 'lq/GET_LEADS_WITH_ACTIVITY_USER',
  GET_LEADS_FOLLOWUP_CONNTACTED: 'lq/GET_LEADS_FOLLOWUP_CONNTACTED',
  GET_OVERALL_STATS: 'lq/GET_OVERALL_STATS',
  GET_OVERALL_STATS_USER: 'lq/GET_OVERALL_STATS_USER',
  GET_LEADS_FOLLOWUP_CONNTACTED_USER: 'lq/GET_LEADS_FOLLOWUP_CONNTACTED_USER',
  GET_FOLLOWUP_TASKS: 'lq/GET_FOLLOWUP_TASKS',
}

export default actions
