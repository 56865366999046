import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Jumbotron,
  Alert,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
} from 'reactstrap'
import { Steps as Intros, message } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { history } from 'index'
import SampleTables from '../../../wizard/Tables'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const { Step } = Intros

const steps = [
  {
    title: 'Get Ready',
    content: (
      <>
        <Jumbotron className={style.jumbotronBg}>
          <div className="row">
            <div className="col-lg-5">
              <div className="mb-5">
                <h5 className="mb-4">
                  <strong>Prepare your data</strong>
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <i className="fe fe-check mr-2" aria-hidden="true" />
                    Upload data of (at least) the last 3 years.
                  </li>
                  <li>
                    <i className="fe fe-check mr-2" aria-hidden="true" />
                    Upload the fields as shown in the example.
                  </li>
                  <li>
                    <i className="fe fe-check mr-2" aria-hidden="true" />
                    Use the sample data to <strong>try</strong> the Lifecycle Segmenter
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-lg-7">
              <PerfectScrollbar>
                <SampleTables />
              </PerfectScrollbar>
            </div>
          </div>
        </Jumbotron>
      </>
    ),
  },
  {
    title: 'Upload file',
    content: (
      <>
        <Jumbotron className={style.jumbotronBg}>
          <h5 className="display-5">Upload File</h5>
          <p>
            {' '}
            Please follow the steps in the upload file process. <b>
              Please keep your browser open
            </b>{' '}
            until the upload has completed
          </p>
        </Jumbotron>
        <div>
          <div
            className={`height-200 d-flex flex-column ${style.head}`}
            style={{
              backgroundImage: 'url(/resources/images/content/wizard/upload-image.png)',
            }}
          >
            <div className="mt-auto mb-3">
              <div className="text-white font-size-24 font-weight-bold pl-4">&nbsp;</div>
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Upload Details',
    content: (
      <>
        <Jumbotron className={style.jumbotronBg}>
          <Row>
            <Col sm="12">
              <Card body>
                <CardTitle tag="h5">File uploaded!</CardTitle>
                <CardText>
                  <p>
                    Once the file is uploaded you will receive a notification as soon as the
                    dashboards are ready.
                    <br />A summary of the data is shown in the third step to be able to validate
                    that all the right data was uploaded successfully.
                  </p>
                </CardText>
              </Card>
            </Col>
          </Row>
        </Jumbotron>
      </>
    ),
  },
  {
    title: 'View Status',
    content: (
      <>
        <Jumbotron className={style.jumbotronBg}>
          <Row>
            <Col sm="12">
              <Card body>
                <CardTitle tag="h5">View the potential</CardTitle>
                <CardText>
                  <Row>
                    <div className="col-md-9">
                      <p className="mt-0">
                        Please wait until the upload has completed As soon as the data is uploaded,
                        we will run the RFM model and send you a notification as soon as the
                        dashboards are ready!
                      </p>
                      <p>
                        <div className="d-flex flex-column justify-content-center">
                          <a
                            onClick={() => {
                              history.push('/rfm/dashboard')
                            }}
                            onKeyDown={() => {
                              history.push('/rfm/dashboard')
                            }}
                            role="presentation"
                            className="btn btn-sm btn-light mr-2"
                          >
                            Go to your dashboard <i className="fe fe-grid" />
                          </a>
                        </div>
                      </p>
                      <p className="mb-0">
                        <strong>Status Legends:</strong>
                      </p>
                      <div className="mb-0">
                        <table className={`${style.table} table table-hover`}>
                          <tbody>
                            <tr>
                              <td className="text-nowrap">
                                <code>Processing</code>
                              </td>
                              <td>
                                <span className="text-primary">
                                  The data is being uploaded to the server
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-nowrap">
                                <code>Submitted</code>
                              </td>
                              <td>
                                <span className="text-primary">
                                  The data is uploaded to the server and the RFM calculations are
                                  running
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-nowrap">
                                <code>successful</code>
                              </td>
                              <td>
                                <span className="text-primary">
                                  The upload was successful and the data is available in the
                                  dashboards
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-nowrap">
                                <code>Error</code>
                              </td>
                              <td>
                                <span className="text-primary">
                                  Something went wrong during the upload process. Please contact us
                                  for support
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <img
                        className="mb-0"
                        src="/resources/images/content/wizard/submitted-uploads.png"
                        alt="Sea"
                      />
                    </div>
                  </Row>
                </CardText>
              </Card>
            </Col>
          </Row>
        </Jumbotron>
      </>
    ),
  },
]

const TopBar = () => {
  const { routerLocation } = useSelector(state => ({
    routerLocation: state.router.location,
  }))
  const [current, setCurrent] = React.useState(0)

  const stepOnboarding = localStorage.getItem('stepOnboarding')

  const [modalShow, setModalShow] = useState(['1', '2', '3', '4'].includes(stepOnboarding) && true)
  const [step, setStep] = useState(
    !stepOnboarding || stepOnboarding === 'done' ? '1' : stepOnboarding,
  )

  const [visible, setVisible] = useState(true)

  if (!routerLocation.pathname.includes('rfm')) {
    return (
      <div className={style.topbar}>
        <div className={style.topbaritem}>
          <UserMenu />
        </div>
      </div>
    )
  }
  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleToggle = () => setModalShow(!modalShow)

  const saveStep = value => {
    localStorage.setItem('stepOnboarding', value)
    setStep(value)
  }

  const handleDecrement = () => {
    const newStep = (+step - 1).toString()
    saveStep(newStep)
  }

  const handleIncrement = () => {
    const newStep = (+step + 1).toString()
    saveStep(newStep)
  }

  const handleDone = () => {
    message.success('Welcome to exsell portal.')
    localStorage.setItem('stepOnboarding', 'done')
    setModalShow(false)
  }

  const onDismiss = () => setVisible(false)

  return (
    <div className={style.topbar}>
      <div className="mr-4 d-none d-md-block">
        <Button color="primary" size="sm" onClick={handleToggle} className="mr-3">
          Getting Started
        </Button>
        <Modal centered className={style.modal} isOpen={modalShow} toggle={handleToggle}>
          <ModalHeader toggle={handleToggle}>Welcome to the portal of Exsell!</ModalHeader>
          <ModalBody>
            <Alert color="primary" type="info" isOpen={visible} toggle={onDismiss} fade={false}>
              <Jumbotron className={style.jumbotronBg}>
                <p className="mb-0">
                  Please follow the steps in the upload process in order to unlock the potential of
                  your business. <br />
                  Don&apos;t forget to click on the ? mark, or use the help-center if you have any
                  questions. You can always send us an e-mail (<strong>contact@exsell.nl</strong>)
                  if you need our assistance.
                </p>
              </Jumbotron>
            </Alert>

            <div className="mb-0">
              <Intros current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Intros>
              <div className="steps-content">{steps[current].content}</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={style.hide}>
              <span className="px-3">
                Step <span className="h6 font-italic">{step}/4</span>
              </span>{' '}
              <Button color="primary" disabled={step === '1'} onClick={handleDecrement}>
                Previous
              </Button>{' '}
              {step !== '4' ? (
                <Button color="primary" onClick={handleIncrement}>
                  Next
                </Button>
              ) : (
                <Button color="primary" onClick={handleDone}>
                  Done
                </Button>
              )}
            </div>

            <div className="steps-action">
              {current > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Previous
                </Button>
              )}

              {current < steps.length - 1 && (
                <Button color="primary" onClick={() => next()}>
                  Next
                </Button>
              )}

              {current === steps.length - 1 && (
                <Button color="primary" onClick={handleDone}>
                  Done
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div className={style.topbaritem}>
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
