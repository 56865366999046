import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.cocAccountValidation_settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  cocAllowed: false,
  loading: false,
  dashboard: false,
  file: [],
  fileState: {},
  inputStatuses: [],
  uploadDetails: [],
  cleanedDetails: [],
  creditSelectDetails: [],
  boughtCreditDetails: [],
  creditDetails: [],
  summary: [],
  textSummary: [],
  marketDetails: [],
  marketCoverage: [],
  heatmap: [],
  provinceMap: [],
  marketBar: [],
  marketPie: [],
  accountDetails: [],
  dataFilters: {
    total_count: 0,
  },
  counterCocData: null,
  counterCreditScore: null,
}

const initialStateSetting = {
  ...STORED_SETTINGS({
    sortedInfo: {
      order: 'descend', // 'ascend'
      columnKey: 'id',
    },
    filteredInfo: {},
    pagination: {
      current: 1,
      pageSize: 10,
    },
    selectedColumns: ['NAM_ORGANIZATION_STATUTORY'],
  }),
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export function settingsReducer(state = initialStateSetting, action) {
  switch (action.type) {
    case actions.SET_STATE_SETTINGS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
