import apiClient from 'services/axios'

export async function getTableData(param, order, offset, limit, filter) {
  return apiClient
    .get(`/api/lq/get_table_data/${param}/${order}/${offset}/${limit}/`, {
      params: filter,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getFunnelData(param, order, offset, limit, filter) {
  return apiClient
    .get(`/api/lq/get_table_data/${param}/${order}/${offset}/${limit}/`, {
      params: filter,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// GET_COMPANY_CLASSIFICATION
// http://127.0.0.1:8002/api/lead_qualification/company/
export async function getCompanyClassifications() {
  return apiClient
    .get(`/api/lead_qualification/company/`)
    .then(response => {
      //      console.log('getCompanyClassification: ', response)
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getFollowUpTask(param, filter) {
  return apiClient
    .get(`/api/lq/get_followup_task_table/${param}/`, {
      params: filter,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTableDataStats(param, filter) {
  return apiClient
    .get(`/api/lq/get_table_data_stats/${param}/`, {
      params: filter,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function classifyLead(company, classification, value = '', date = null) {
  if (date) {
    return apiClient
      .post('/api/classification/', { company, classification, value, date })
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(() => {
        return false
      })
  }

  return apiClient
    .post('/api/classification/', { company, classification, value })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function classifyFollowUpTask(company, classification, value = '', date) {
  if (date) {
    return apiClient
      .post('/api/classification/', { company, classification, value, date })
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(() => {
        return false
      })
  }

  return apiClient
    .post('/api/classification/', { company, classification, value })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function createNote(company, note) {
  return apiClient
    .post('/api/note/', {
      company,
      note,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function deleteNote(note) {
  return apiClient
    .delete(`/api/note/${note}/`)
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function deleteAdditional(additional) {
  return apiClient
    .delete(`/api/additional/${additional}/`)
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function createActivity(
  company,
  registerDate,
  result,
  followupDate = null,
  note = null,
) {
  return apiClient
    .post('/api/activity/', {
      company,
      register_date: registerDate,
      result,
      followup_date: followupDate,
      note,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function deleteActivity(id) {
  return apiClient
    .delete(`/api/activity/${id}/`)
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function createContactperson(data) {
  return apiClient
    .post('/api/contactperson/', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function updateContactperson(data) {
  return apiClient
    .put(`/api/contactperson/${data.id}/`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function updateCompany(data) {
  return apiClient
    .put(`/api/company/${data.id}/`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function createAdditional(company, contractEndDate, annualValue, currentSupplier) {
  return apiClient
    .post('/api/additional/', {
      company,
      current_supplier: currentSupplier,
      contract_end_date: contractEndDate,
      annual_value: annualValue,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

// dashboard
export async function getActivitiesPerResultType() {
  return apiClient
    .get(`/api/lq/statistics/activities_per_result`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getActivitiesPerResultTypeUser() {
  return apiClient
    .get(`/api/lq/statistics/activities_per_result_user`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLeadsClassified() {
  return apiClient
    .get(`/api/lq/statistics/leads_classified`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLeadsClassifiedUser() {
  return apiClient
    .get(`/api/lq/statistics/leads_classified_user`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLeadsWithActivity() {
  return apiClient
    .get(`/api/lq/statistics/leads_with_activity`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLeadsWithActivityUser() {
  return apiClient
    .get(`/api/lq/statistics/leads_with_activity_user`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLeadsFollowupConntacted() {
  return apiClient
    .get(`/api/lq/statistics/leads_followup_conntacted`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLeadsFollowupConntactedUser() {
  return apiClient
    .get(`/api/lq/statistics/leads_followup_conntacted_user`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getOverallStats() {
  return apiClient
    .get(`/api/lq/statistics/overall_stats`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getOverallStatsUser() {
  return apiClient
    .get(`/api/lq/statistics/overall_stats_user`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getReactivation() {
  return apiClient
    .get(`/api/lq/statistics/reactivation`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
