import { all, put, call, takeEvery } from 'redux-saga/effects'
import getMenuData from 'services/menu/dev/index'
import actions from './actions'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export function* GET_USER_MENU_DATA({ payload }) {
  const { admin, superuser, products } = payload

  const menuData = yield call(getMenuData, admin, superuser, products)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_MENU_DATA, GET_USER_MENU_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
