import moment from 'moment'
import store from 'store'
import actions from './actions'

const startDate = moment().format('YYYY-MM-DD') // new Date()
const endDate = moment()
  .add(7, 'days')
  .format('YYYY-MM-DD')

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.lq_settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  data: [],
  followUpTasks: {
    data: [],
    loading: false,
  },
  dataFilters: {
    total_count: 0,
  },
  dataLoading: false,
  loading: false,
  funnelDataLoading: false,
  activitiePerResultType: [],
  activitiePerResultTypeUser: [],
  leadsClassified: [],
  leadsClassifiedUser: [],
  leadsWithActivity: [],
  leadsWithActivityUser: [],
  leadsFollowupConntacted: [],
  leadsFollowupConntactedUser: [],
  overallStats: [],
  overallStatsUser: [],
  companyClassifications: [],
  funnelData: [],
}

const initialStateSetting = {
  ...STORED_SETTINGS({
    leadsToFetch: 'all', // all
    sortedInfo: {
      order: 'descend', // 'ascend'
      columnKey: 'lead_score',
    },
    filteredInfo: {
      classification: ['Follow-up'],
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
    leadsToFetchFollowUpTasks: 'my', // all
    sortedInfoFollowUpTasks: {
      order: 'descend', // 'ascend'
      columnKey: 'latest_activity_followup_date',
    },
    filteredInfoFollowUpTasks: {},
    paginationFollowUpTasks: {
      current: 1,
      pageSize: 10,
    },
    dateRangeFollowUpTasks: [startDate, endDate],
  }),
}

export function lqReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.UPDATE_STATE_ADDITIONAL:
      state.data
        .find(o => o.id === action.payload.company)
        .additional.push(action.payload.additional)
      return state
    case actions.UPDATE_STATE_ADDITIONAL_FOLLOWUP:
      state.followUpTasks.data
        .find(o => o.id === action.payload.company)
        .additional.push(action.payload.additional)
      return state

    case actions.UPDATE_DELETE_STATE_ADDITIONAL: {
      const index = state.data[action.payload.companyIndex].additional.findIndex(
        n => n.id === action.payload.additionalId,
      )
      state.data[action.payload.companyIndex].additional.splice(index, 1)
      return state
    }
    case actions.UPDATE_DELETE_STATE_ADDITIONAL_FOLLOWUP: {
      const index = state.followUpTasks.data[action.payload.companyIndex].additional.findIndex(
        n => n.id === action.payload.additionalId,
      )
      state.followUpTasks.data[action.payload.companyIndex].additional.splice(index, 1)
      return state
    }

    case actions.UPDATE_STATE_ACTION:
      state.data.find(o => o.id === action.payload.company).activities.push(action.payload.activity)
      return state
    case actions.UPDATE_STATE_ACTION_FOLLOWUP:
      state.followUpTasks.data
        .find(o => o.id === action.payload.company)
        .activities.push(action.payload.activity)
      return state

    case actions.UPDATE_DELETE_STATE_ACTIVITY: {
      const index = state.data[action.payload.companyIndex].activities.findIndex(
        n => n.id === action.payload.activityId,
      )
      state.data[action.payload.companyIndex].activities.splice(index, 1)
      return state
    }
    case actions.UPDATE_DELETE_STATE_ACTIVITY_FOLLOWUP: {
      const index = state.followUpTasks.data[action.payload.companyIndex].activities.findIndex(
        n => n.id === action.payload.activityId,
      )
      state.followUpTasks.data[action.payload.companyIndex].activities.splice(index, 1)
      return state
    }

    case actions.UPDATE_STATE_NOTE:
      state.data.find(o => o.id === action.payload.company).notes.push(action.payload.note)
      return state
    case actions.UPDATE_STATE_NOTE_FOLLOWUP:
      state.followUpTasks.data
        .find(o => o.id === action.payload.company)
        .notes.push(action.payload.note)
      return state

    case actions.UPDATE_DELETE_STATE_NOTE:
      state.data[action.payload.companyIndex].notes.splice(
        state.data[action.payload.companyIndex].notes.findIndex(
          n => n.id === action.payload.noteId,
        ),
        1,
      )
      return state
    case actions.UPDATE_DELETE_STATE_NOTE_FOLLOWUP:
      state.followUpTasks.data[action.payload.companyIndex].notes.splice(
        state.followUpTasks.data[action.payload.companyIndex].notes.findIndex(
          n => n.id === action.payload.noteId,
        ),
        1,
      )
      return state

    case actions.UPDATE_STATE_CLASSIFICATION:
      if (state.data > 0) {
        state.data.find(o => o.id === action.payload.company).classification =
          action.payload.classification
      }
      return state
    case actions.UPDATE_STATE_CLASSIFICATION_FOLLOWUP:
      if (state.followUpTasks.length > 0) {
        state.followUpTasks.data.find(o => o.id === action.payload.company).classification =
          action.payload.classification
      }

      return state

    case actions.UPDATE_STATE_CONTACTPERSON: {
      const cpList = state.data.find(o => o.id === action.payload.company)
      const idx = cpList.contactperson.findIndex(c => c.id === action.payload.contactperson.id)
      state.data.find(o => o.id === action.payload.company).contactperson[idx] =
        action.payload.contactperson
      return state
    }
    case actions.UPDATE_STATE_CONTACTPERSON_FOLLOWUP: {
      const cpList = state.followUpTasks.data.find(o => o.id === action.payload.company)
      const idx = cpList.contactperson.findIndex(c => c.id === action.payload.contactperson.id)
      state.followUpTasks.data.find(o => o.id === action.payload.company).contactperson[idx] =
        action.payload.contactperson
      return state
    }

    case actions.SET_STATE_CONTACTPERSON: {
      state.data
        .find(o => o.id === action.payload.company)
        .contactperson.push(action.payload.contactperson)
      return state
    }
    case actions.SET_STATE_CONTACTPERSON_FOLLOWUP: {
      state.followUpTasks.data
        .find(o => o.id === action.payload.company)
        .contactperson.push(action.payload.contactperson)
      return state
    }

    case actions.UPDATE_STATE_COMPANY: {
      const idx = state.data.findIndex(c => c.id === action.payload.id)
      state.data[idx].street = action.payload.street
      state.data[idx].housenumber = action.payload.housenumber
      state.data[idx].housenumber_addition = action.payload.housenumber_addition
      state.data[idx].ID_Company_Concern = action.payload.ID_Company_Concern
      state.data[idx].company_phone = action.payload.company_phone

      return state
    }
    case actions.UPDATE_STATE_COMPANY_FOLLOWUP: {
      const idx = state.followUpTasks.data.findIndex(c => c.id === action.payload.id)
      state.followUpTasks.data[idx].street = action.payload.street
      state.followUpTasks.data[idx].housenumber = action.payload.housenumber
      state.followUpTasks.data[idx].housenumber_addition = action.payload.housenumber_addition
      state.followUpTasks.data[idx].ID_Company_Concern = action.payload.ID_Company_Concern
      state.followUpTasks.data[idx].company_phone = action.payload.company_phone

      return state
    }
    default:
      return state
  }
}

export function ldSettingsReducer(state = initialStateSetting, action) {
  switch (action.type) {
    case actions.SET_STATE_SETTINGS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
