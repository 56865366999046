import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getUsers,
  createUser,
  removeUser,
  getAccounts,
  getAccount,
  editAccount,
  createAccount,
  deleteAccount,
} from 'services/account'
import { notification } from 'antd'
import actions from './actions'

export function* GET_USERS({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { accountId } = payload

  const users = accountId == null ? yield call(getUsers) : yield call(getUsers, accountId)

  if (users) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        users,
      },
    })
  }

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_USER({ payload }) {
  const { email, password, name, accountId } = payload
  const response = yield call(createUser, email, password, name, accountId)
  if (response) {
    notification.success({
      message: 'successful Created',
    })
    yield put({
      type: 'account/GET_USERS',
      payload: {
        accountId,
      },
    })
  }
}

export function* REMOVE_USER({ payload }) {
  const { id } = payload
  const response = yield call(removeUser, id)
  if (response) {
    notification.success({
      message: 'user removed',
    })
    yield put({
      type: 'account/GET_USERS',
      payload: { accountId: null },
    })
  }
}

export function* GET_ACCOUNTS() {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const accounts = yield call(getAccounts)
  if (accounts) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        accounts,
      },
    })
  }

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { accountId } = payload
  const account = yield call(getAccount, accountId)
  if (account) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        selectedAccount: account,
      },
    })
  }

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { accountId } = payload
  const account = yield call(deleteAccount, accountId)
  if (account) {
    notification.success({
      message: 'account removed',
    })
    yield put({
      type: 'account/GET_ACCOUNTS',
    })
  }

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EDIT_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })

  // eslint-disable-next-line camelcase
  const { id, name, email, IDI_client } = payload
  const account = yield call(editAccount, id, name, email, IDI_client)

  if (account) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        selectedAccount: account,
      },
    })
  }

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })

  // eslint-disable-next-line camelcase
  const { name, email, IDI_client } = payload
  const account = yield call(createAccount, name, email, IDI_client)

  if (account) {
    yield put({
      type: 'account/UPDATE_STATE_ACCOUNTS',
      payload: {
        account,
      },
    })
  }

  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USERS, GET_USERS),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.REMOVE_USER, REMOVE_USER),
    takeEvery(actions.GET_ACCOUNTS, GET_ACCOUNTS),
    takeEvery(actions.GET_ACCOUNT, GET_ACCOUNT),
    takeEvery(actions.EDIT_ACCOUNT, EDIT_ACCOUNT),
    takeEvery(actions.CREATE_ACCOUNT, CREATE_ACCOUNT),
    takeEvery(actions.DELETE_ACCOUNT, DELETE_ACCOUNT),
  ])
}
