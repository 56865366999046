import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import store from 'store'
import { notification } from 'antd'
import {
  getTableData,
  getFunnelData,
  getFollowUpTask,
  getTableDataStats,
  classifyLead,
  classifyFollowUpTask,
  createNote,
  deleteNote,
  deleteAdditional,
  createActivity,
  createContactperson,
  createAdditional,
  updateContactperson,
  updateCompany,
  getActivitiesPerResultType,
  getLeadsClassified,
  getLeadsWithActivity,
  getLeadsFollowupConntacted,
  getOverallStats,
  getOverallStatsUser,
  getLeadsWithActivityUser,
  getLeadsClassifiedUser,
  getLeadsFollowupConntactedUser,
  getActivitiesPerResultTypeUser,
  deleteActivity,
  getCompanyClassifications,
} from 'services/lq'
import actions from './actions'

export function* GET_TABLEDATA({ param, order, offset, limit, filter = '' }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      dataLoading: true,
    },
  })
  const data = yield call(getTableData, param, order, offset, limit, filter)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        data,
      },
    })
  }

  yield put({
    type: 'lq/SET_STATE',
    payload: {
      dataLoading: false,
    },
  })
}

export function* GET_FUNNEL_DATA({ param, order, offset, filter = '' }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      funnelDataLoading: true,
    },
  })
  const dataFilters = yield call(getTableDataStats, param, filter)
  yield put({
    type: 'lq/GET_COMPANY_CLASSIFICATION',
  })
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      dataFilters,
    },
  })
  const data = yield call(getFunnelData, param, order, offset, dataFilters?.total_count, filter)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        funnelData: data,
      },
    })
  }

  yield put({
    type: 'lq/SET_STATE',
    payload: {
      funnelDataLoading: false,
    },
  })
}

export function* GET_FOLLOWUP_TASKS({ param, filter = '' }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      followUpTasks: {
        data: [],
        loading: true,
      },
    },
  })
  const data = yield call(getFollowUpTask, param, filter)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        followUpTasks: {
          data,
          loading: false,
        },
      },
    })
  }
}

export function* GET_TABLEDATA_STATS({ param, filter = '' }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const dataFilters = yield call(getTableDataStats, param, filter)
  if (dataFilters) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        dataFilters,
      },
    })
  }

  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CLASSIFY_LEAD({ payload, followUpTable }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { company, classification, value, date, today, source } = payload // TODO REVIEW _GEN_25
  const response = yield call(classifyLead, company, classification, value, date) // TODO REVIEW _GEN_25
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_CLASSIFICATION
          : actions.UPDATE_STATE_CLASSIFICATION_FOLLOWUP,
      payload: {
        company,
        classification,
        value,
        date: today,
      },
    })
    yield put({
      type: 'lq/GET_COMPANY_CLASSIFICATION',
    })

    if (!source) {
      notification.success({
        message: 'Success',
        description: 'Lead Qualification updated',
      })
    }
  }

  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CLASSIFY_FOLLOW_UP_TASK({ payload, followUpTable }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { company, classification, value, date } = payload // TODO REVIEW _GEN_25
  const response = yield call(classifyFollowUpTask, company, classification, value, date) // TODO REVIEW _GEN_25

  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_CLASSIFICATION
          : actions.UPDATE_STATE_CLASSIFICATION_FOLLOWUP,
      payload: {
        company,
        classification,
        value,
        date,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Follow-Up Task updated',
    })
  }

  yield put({
    type: 'lq/GET_COMPANY_CLASSIFICATION',
  })

  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_NOTE({ payload, followUpTable }) {
  const { company, note } = payload
  const response = yield call(createNote, company, note)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_NOTE
          : actions.UPDATE_STATE_NOTE_FOLLOWUP,

      payload: {
        note: {
          id: response.id,
          note: response.note,
          created_at: response.created_at,
          user: response.user,
        },
        company: response.company,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Note is posted',
    })
  }
}

export function* DELETE_NOTE({ payload, followUpTable }) {
  const { companyIndex, noteId } = payload
  const response = yield call(deleteNote, noteId)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_DELETE_STATE_NOTE
          : actions.UPDATE_DELETE_STATE_NOTE_FOLLOWUP,

      payload: {
        companyIndex,
        noteId,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Note is deleted',
    })
  }
}

export function* CREATE_ACTIVITY({ payload, followUpTable }) {
  const { company, result, registerDate, followupDate, note } = payload
  const response = yield call(createActivity, company, registerDate, result, followupDate, note)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_ACTION
          : actions.UPDATE_STATE_ACTION_FOLLOWUP,

      payload: {
        activity: {
          id: response.id,
          result: response.result,
          register_date: response.register_date,
          created_at: response.created_at,
          user: response.user,
          followup_date: response.followup_date,
          note: response.note,
        },
        company: response.company,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Activitiy is registered',
    })
  }
}

export function* DELETE_ACTIVITY({ payload, followUpTable }) {
  const { companyIndex, activityId } = payload
  const response = yield call(deleteActivity, activityId)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_DELETE_STATE_ACTIVITY
          : actions.UPDATE_DELETE_STATE_ACTIVITY_FOLLOWUP,

      payload: {
        companyIndex,
        activityId,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Activity is deleted',
    })
  }
}

export function* DELETE_ADDITIONAL({ payload, followUpTable }) {
  const { companyIndex, additionalId } = payload
  const response = yield call(deleteAdditional, additionalId)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_DELETE_STATE_ADDITIONAL
          : actions.UPDATE_DELETE_STATE_ADDITIONAL_FOLLOWUP,
      payload: {
        companyIndex,
        additionalId,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Classification is deleted',
    })
  }
}

export function* CREATE_CONTACTPERSON({ payload, followUpTable }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(createContactperson, payload)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.SET_STATE_CONTACTPERSON
          : actions.SET_STATE_CONTACTPERSON_FOLLOWUP,
      payload: {
        contactperson: {
          id: response.id,
          first_name: response.first_name ?? '-',
          last_name: response.last_name ?? '-',
          prefix: response.prefix ?? '-',
          function: response.function ?? '-',
          phone: response.phone ?? '-',
          email: response.email ?? '-',
          gender: response.gender ?? '-',
        },
        company: response.company,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Contact Person is Added',
    })
  }
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_ADDITIONAL_DATA({ payload, followUpTable }) {
  const { company, contractEndDate, annualValue, currentSupplier } = payload
  const response = yield call(
    createAdditional,
    company,
    contractEndDate,
    annualValue,
    currentSupplier,
  )
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_ADDITIONAL
          : actions.UPDATE_STATE_ADDITIONAL_FOLLOWUP,
      payload: {
        additional: {
          id: response.id,
          contract_end_date: response.contract_end_date,
          annual_value: response.annual_value,
          created_at: response.created_at,
          user: response.user,
          current_supplier: response.current_supplier,
        },
        company: response.company,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Additional data is stored',
    })
  }
}

export function* EDIT_CONTACTPERSON({ payload, followUpTable }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateContactperson, payload)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_CONTACTPERSON
          : actions.UPDATE_STATE_CONTACTPERSON_FOLLOWUP,
      payload: {
        contactperson: {
          id: response.id,
          first_name: response.first_name ?? '-',
          last_name: response.last_name ?? '-',
          prefix: response.prefix ?? '-',
          function: response.function ?? '-',
          phone: response.phone ?? '-',
          email: response.email ?? '-',
          gender: response.gender ?? '-',
        },
        company: response.company,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Contact Person is Updated',
    })
  }
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EDIT_COMPANY({ payload, followUpTable }) {
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(updateCompany, payload)
  if (response) {
    yield put({
      type:
        typeof followUpTable === 'undefined'
          ? actions.UPDATE_STATE_COMPANY
          : actions.UPDATE_STATE_COMPANY_FOLLOWUP,
      payload: response,
    })

    // to refresh the component with
    // a new updated data
    yield put({
      type: 'lq/GET_COMPANY_CLASSIFICATION',
    })

    notification.success({
      message: 'Success',
      description: 'Company is Updated',
    })
  }
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// Settings
export function* SET_LEADSTOFETCH({ payload }) {
  const { leadsToFetch } = payload
  yield put({
    type: 'lq/SET_STATE',
    payload: {
      leadsToFetch,
    },
  })
}

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.lq_settings.${setting}`, value)
  yield put({
    type: 'lq/SET_STATE_SETTINGS',
    payload: {
      [setting]: value,
    },
  })
}

// Dashboard
export function* GET_ACTIVITIES_PER_RESULT_TYPE() {
  const data = yield call(getActivitiesPerResultType)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        activitiePerResultType: data,
      },
    })
  }
}

// Get Company Classifications
export function* GET_COMPANY_CLASSIFICATION() {
  const data = yield call(getCompanyClassifications)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        companyClassifications: data,
      },
    })
  }
}

export function* GET_ACTIVITIES_PER_RESULT_TYPE_USER() {
  const data = yield call(getActivitiesPerResultTypeUser)
  if (data) {
    const data2 = data.slice(1).map(item => {
      return {
        id: item[0],
        name: item[1],
        email: item[2],
        // "data": {"series":  item.slice(3), "labels": data[0] },
        data: {
          labels: data[0],
          datasets: [
            {
              data: item.slice(3),
              backgroundColor: [
                '#0074D9',
                '#FF4136',
                '#2ECC40',
                '#FF851B',
                '#7FDBFF',
                '#B10DC9',
                '#FFDC00',
                '#001f3f',
                '#39CCCC',
                '#01FF70',
                '#85144b',
                '#F012BE',
                '#3D9970',
                '#111111',
                '#AAAAAA',
              ],
            },
          ],
        },
      }
    })

    yield put({
      type: 'lq/SET_STATE',
      payload: {
        activitiePerResultTypeUser: data2,
      },
    })
  }
}

export function* GET_LEADS_CLASSIFIED() {
  const data = yield call(getLeadsClassified)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        leadsClassified: data,
      },
    })
  }
}

export function* GET_LEADS_CLASSIFIED_USER() {
  const data = yield call(getLeadsClassifiedUser)
  if (data) {
    const data2 = data.map(item => {
      return {
        id: item[0],
        name: item[1],
        email: item[2],
        data: { series: [{ data: item.slice(3) }] },
        latest: item[item.length - 1],
      }
    })

    yield put({
      type: 'lq/SET_STATE',
      payload: {
        leadsClassifiedUser: data2,
      },
    })
  }
}

export function* GET_LEADS_WITH_ACTIVITY() {
  const data = yield call(getLeadsWithActivity)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        leadsWithActivity: data,
      },
    })
  }
}

export function* GET_LEADS_WITH_ACTIVITY_USER() {
  const data = yield call(getLeadsWithActivityUser)
  if (data) {
    const data2 = data.map(item => {
      return {
        id: item[0],
        name: item[1],
        email: item[2],
        data: { series: [{ data: item.slice(3) }] },
        latest: item[item.length - 1],
      }
    })

    yield put({
      type: 'lq/SET_STATE',
      payload: {
        leadsWithActivityUser: data2,
      },
    })
  }
}

export function* GET_LEADS_FOLLOWUP_CONNTACTED() {
  const data = yield call(getLeadsFollowupConntacted)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        leadsFollowupConntacted: data,
      },
    })
  }
}

export function* GET_LEADS_FOLLOWUP_CONNTACTED_USER() {
  const data = yield call(getLeadsFollowupConntactedUser)
  if (data) {
    const data2 = data.map(item => {
      return {
        id: item[0],
        name: item[1],
        email: item[2],
        data: { series: [{ data: item.slice(3) }] },
        latest: item[item.length - 1],
      }
    })

    yield put({
      type: 'lq/SET_STATE',
      payload: {
        leadsFollowupConntactedUser: data2,
      },
    })
  }
}

export function* GET_OVERALL_STATS() {
  const data = yield call(getOverallStats)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        overallStats: data,
      },
    })
  }
}

export function* GET_OVERALL_STATS_USER() {
  const data = yield call(getOverallStatsUser)
  if (data) {
    yield put({
      type: 'lq/SET_STATE',
      payload: {
        overallStatsUser: data,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TABLEDATA, GET_TABLEDATA),
    takeLatest(actions.GET_FUNNEL_DATA, GET_FUNNEL_DATA),
    takeEvery(actions.GET_COMPANY_CLASSIFICATION, GET_COMPANY_CLASSIFICATION),
    takeEvery(actions.GET_TABLEDATA_STATS, GET_TABLEDATA_STATS),
    takeEvery(actions.CLASSIFY_LEAD, CLASSIFY_LEAD),
    takeEvery(actions.CLASSIFY_FOLLOW_UP_TASK, CLASSIFY_FOLLOW_UP_TASK),
    takeEvery(actions.CREATE_NOTE, CREATE_NOTE),
    takeEvery(actions.DELETE_NOTE, DELETE_NOTE),
    takeEvery(actions.CREATE_ACTIVITY, CREATE_ACTIVITY),
    takeEvery(actions.DELETE_ACTIVITY, DELETE_ACTIVITY),
    takeEvery(actions.CREATE_CONTACTPERSON, CREATE_CONTACTPERSON),
    takeEvery(actions.EDIT_CONTACTPERSON, EDIT_CONTACTPERSON),
    takeEvery(actions.EDIT_COMPANY, EDIT_COMPANY),
    takeEvery(actions.SET_LEADSTOFETCH, SET_LEADSTOFETCH),
    takeEvery(actions.CREATE_ADDITIONAL_DATA, CREATE_ADDITIONAL_DATA),
    takeEvery(actions.DELETE_ADDITIONAL, DELETE_ADDITIONAL),
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.GET_ACTIVITIES_PER_RESULT_TYPE, GET_ACTIVITIES_PER_RESULT_TYPE),
    takeEvery(actions.GET_LEADS_CLASSIFIED, GET_LEADS_CLASSIFIED),
    takeEvery(actions.GET_LEADS_CLASSIFIED_USER, GET_LEADS_CLASSIFIED_USER),
    takeEvery(actions.GET_LEADS_WITH_ACTIVITY, GET_LEADS_WITH_ACTIVITY),
    takeEvery(actions.GET_LEADS_WITH_ACTIVITY_USER, GET_LEADS_WITH_ACTIVITY_USER),
    takeEvery(actions.GET_LEADS_FOLLOWUP_CONNTACTED, GET_LEADS_FOLLOWUP_CONNTACTED),
    takeEvery(actions.GET_OVERALL_STATS, GET_OVERALL_STATS),
    takeEvery(actions.GET_OVERALL_STATS_USER, GET_OVERALL_STATS_USER),
    takeEvery(actions.GET_LEADS_FOLLOWUP_CONNTACTED_USER, GET_LEADS_FOLLOWUP_CONNTACTED_USER),
    takeEvery(actions.GET_ACTIVITIES_PER_RESULT_TYPE_USER, GET_ACTIVITIES_PER_RESULT_TYPE_USER),
    takeEvery(actions.GET_FOLLOWUP_TASKS, GET_FOLLOWUP_TASKS),
  ])
}
