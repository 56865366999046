import apiClient from 'services/axios'

export async function getDropDown() {
  return apiClient
    .get('api/coc_account_validation/file_dropdown/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getCanRun() {
  return apiClient
    .get('api/coc_account_validation/can_run/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getInputStatuses() {
  return apiClient
    .get('api/coc_account_validation/input/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUploadDetails() {
  return apiClient
    .get('api/coc_account_validation/get_upload_details/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSummary(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/summary/', { params: { file: selectedFile } })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTextSummary(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/summarytext/', { params: { file: selectedFile } })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getBar(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/marketbar/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getPie(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/creditchart/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getMarketDetails(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/marketdetails/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getHeatmap(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/heatmap/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProvincemap(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/province_map/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getCleanedDetails(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/cleaneddetails/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getMarketCoverage(selectedFile) {
  return apiClient
    .get('api/coc_account_validation/marketcoverage/', { params: selectedFile })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAccountDetails(order, selectedFile) {
  console.log('fileselectedFile', selectedFile)
  return apiClient
    .get(`/api/coc_account_validation/accountdetails/${order}/`, {
      params: selectedFile,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTableDataStats(fields, filter) {
  return apiClient
    .post(`/api/coc_account_validation/get_table_data_stats/`, { fields }, { params: filter })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getCounter(counter) {
  return apiClient
    .get(`/api/coc_account_validation/counter/${counter}/`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getCreditDetails() {
  return apiClient
    .get('api/coc_account_validation/creditdetails/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setCreditDetails(keys) {
  return apiClient
    .post('/api/coc_account_validation/creditdetails/', keys)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getBoughtCreditDetails() {
  return apiClient
    .get('/api/coc_account_validation/boughtcreditdetails/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
