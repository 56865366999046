import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import {
  login,
  register,
  currentAccount,
  logout,
  setPassword,
  resetPassword,
  resetPasswordConfirm,
  accountData,
} from 'services/jwt'
import { getProducts } from 'services/products'
import actionsProduct from 'redux/product/actions'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  if (success) {
    const stepOnboarding = localStorage.getItem('stepOnboarding')
    if (!stepOnboarding && stepOnboarding !== 'done') {
      localStorage.setItem('stepOnboarding', 1)
    }
    LOAD_CURRENT_ACCOUNT()
    // yield put({
    //   type: 'user/LOAD_CURRENT_ACCOUNT',
    // })
    yield history.push('/home')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'successful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(currentAccount)
  if (response) {
    const { user, account } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: user.id,
        name: user.name,
        email: user.email,
        is_superuser: user.is_superuser,
        account,
        authorized: true,
      },
    })

    const productData = yield call(getProducts, account.id)
    if (productData) {
      yield put({
        type: actionsProduct.SET_STATE,
        payload: {
          productData,
        },
      })
    }

    yield put({
      type: 'menu/GET_USER_MENU_DATA',
      payload: {
        admin: account.is_admin ?? false,
        superuser: user.is_superuser,
        products: productData,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* SET_PASSWORD({ payload }) {
  const { newPassword, oldPassword } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(setPassword, newPassword, oldPassword)
  if (response === true) {
    notification.success({
      message: 'Success',
      description: 'Password changed',
    })
  } /*  else {
    notification.error({
      message: 'Error',
      description: response.detail.current_password ?? response.detail.new_password,
    })
  } */

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD(payload) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { email } = payload.payload
  const response = yield call(resetPassword, email)
  if (response === true) {
    notification.success({
      message: 'Success',
      description: 'Check your email',
    })
  } /* else {
    notification.error({
      message: 'Error',
      description: response.detail.email,
    })
  } */

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* RESET_PASSWORD_CONFIRM({ payload }) {
  const { newPassword, uid, token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const userData = yield call(accountData, uid, token)
  if (userData !== false) {
    const response = yield call(resetPasswordConfirm, newPassword, uid, token)
    if (response === true) {
      notification.success({
        message: 'Success',
        description: 'Password changed',
      })
    }

    yield put({
      type: actions.LOGIN,
      payload: {
        email: userData.user.email,
        password: newPassword,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.SET_PASSWORD, SET_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.RESET_PASSWORD_CONFIRM, RESET_PASSWORD_CONFIRM),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
