import actions from './actions'

const initialState = {
  recencyFrequency: {
    data: [],
    loading: false,
  },
  recencyFrequencyMonetary: {
    data: [],
    loading: false,
  },
  numCustomersNumOrders: {
    data: [],
    loading: false,
  },
  frequencyMonetaryValue: {
    data: [],
    loading: false,
  },
  monetaryGroupValue: {
    data: [],
    loading: false,
  },
  seasonality: {
    data: [],
    loading: false,
  },
  newRepeatRecency: {
    data: [],
    loading: false,
  },
  riskOfLosing: {
    data: [],
    loading: false,
  },
  reactivation: {
    data: [],
    loading: false,
  },
  lostPerQuarter: {
    data: [],
    loading: false,
  },
  migration: {
    data: [],
    loading: false,
  },
  topTen: {
    data: [],
    loading: false,
  },
  currentPage: 'home',
  currentSubPage: 'overall',
  sortedInfo: {
    order: 'descend', // 'ascend'
    columnKey: 'monetary_value',
  },
  filteredInfo: {},
  pagination: {
    current: 1,
    pageSize: 10,
  },
  sortedInfoMigration: {
    order: 'descend', // 'ascend'
    columnKey: 'monetary_value',
  },
  filteredInfoMigration: {},
  paginationMigration: {
    current: 1,
    pageSize: 10,
  },

  loading: false,
}

const actionTableInitialState = {
  data: [],
  loading: false,
  filteredInfo: {},
  paginationInfo: {
    current: 1,
    pageSize: 20,
  },
  dataFilters: {
    total_count: 0,
  },
  sortedInfo: {
    order: 'ascend',
    columnKey: 'monetary_value',
  },
}
export function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export function reducerActionTable(state = actionTableInitialState, action) {
  switch (action.type) {
    case actions.SET_ACTION_TABLE_STATE:
      return { ...state, ...action.payload }
    case actions.CHANGE_ACTION_TABLE_ACTION:
      state.data.find(o => o.id === action.payload.id).action = action.payload.action
      return state
    default:
      return state
  }
}
export default reducer
