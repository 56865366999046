import apiClient from 'services/axios'
// GET PRODUCTS
export async function getProducts() {
  return apiClient
    .get('/api/get_products/')
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function getRFMresults() {
  return apiClient
    .get('api/inputrfm/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getCanRunRFM() {
  return apiClient
    .get('api/rfm/can_run/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAccountProducts(accountID) {
  const url = accountID == null ? `/api/accountproduct/` : `/api/accountproduct/${accountID}/`
  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function postAccountProducts(accountId, product, expiresAt, isActive, orderId, limit) {
  return apiClient
    .post(`/api/accountproduct/`, {
      account_id: accountId,
      expires_at: expiresAt,
      product_id: product,
      is_active: isActive,
      order_id: orderId,
      limit,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function putAccountProducts(id, product, expiresAt, isActive, orderId, limit) {
  return apiClient
    .patch(`/api/accountproduct/${id}/`, {
      expires_at: expiresAt,
      product,
      is_active: isActive,
      order_id: orderId,
      limit,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteAccountProducts(id) {
  return apiClient
    .delete(`/api/accountproduct/${id}/`)
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
