const actions = {
  SET_STATE: 'product/SET_STATE',
  UPDATE_STATE_ACCOUNTPRODUCTS: 'product/UPDATE_STATE_ACCOUNTPRODUCTS',
  UPDATE_DELETE_ACCOUNTPRODUCTS: 'product/UPDATE_DELETE_ACCOUNTPRODUCTS',
  ADD_STATE_ACCOUNTPRODUCTS: 'product/ADD_STATE_ACCOUNTPRODUCTS',
  GET_DATA: 'product/GET_DATA',
  GET_ACCOUNTPRODUCTS: 'product/GET_ACCOUNTPRODUCTS',
  EDIT_ACCOUNTPRODUCTS: 'product/EDIT_ACCOUNTPRODUCTS',
  ADD_ACCOUNTPRODUCTS: 'product/ADD_ACCOUNTPRODUCTS',
  DELETE_ACCOUNTPRODUCTS: 'product/DELETE_ACCOUNTPRODUCTS',
  GET_RFM_RESULTS: 'product/GET_RFM_RESULTS',
  GET_CAN_RUN_RFM: 'product/GET_CAN_RUN_RFM',
}

export default actions
