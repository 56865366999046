import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/auth/jwt/create/', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const accessToken = response.data.access
        const refreshToken = response.data.refresh
        if (accessToken && refreshToken) {
          store.set('accessToken', accessToken)
          store.set('refreshToken', refreshToken)
          window.location = '/home'
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function currentAccount() {
  return apiClient
    .get('auth/users/me/')
    .then(response => {
      if (response) {
        const accessToken = response.data.access
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function logout() {
  store.remove('accessToken')
}

export async function setPassword(newPassword, currentPassword) {
  return apiClient
    .post('/auth/users/set_password/', {
      new_password: newPassword,
      current_password: currentPassword,
      re_new_password: newPassword,
    })
    .then(response => {
      if (response.error) {
        return response
      }
      return true
    })
    .catch(() => {
      return false
    })
}

export async function resetPassword(email) {
  return apiClient
    .post('/auth/users/reset_password/', { email })
    .then(response => {
      if (response.error) {
        return response
      }
      return true
    })
    .catch(() => {
      return false
    })
}

export async function resetPasswordConfirm(newPassword, uid, token) {
  return apiClient
    .post('/auth/users/reset_password_confirm/', {
      new_password: newPassword,
      re_new_password: newPassword,
      uid,
      token,
    })
    .then(response => {
      if (response.error) {
        return response
      }
      return true
    })
    .catch(() => {
      return false
    })
}

export async function accountData(uid, token) {
  return apiClient
    .get(`auth/users/user_data_token_uid/${uid}/${token}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}
