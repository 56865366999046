import apiClient from 'services/axios'

export async function getAll() {
  return apiClient
    .get('auth/users/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function get(id) {
  return apiClient
    .get(`auth/users/${id}/`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function update(id, name, email, isAdmin, isStaff, isSuperuser) {
  return apiClient
    .patch(`auth/userupdate/${id}/`, {
      is_account_admin: isAdmin,
      is_staff: isStaff,
      is_superuser: isSuperuser,
      name,
      email,
      id,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}
