import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

// Response interceptor for API calls
apiClient.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (!error.response) {
      notification.error({
        message: "Can't connect to the server",
      })
      return Promise.reject(error)
    }
    const originalRequest = error.config
    if (
      error.response.status === 401 &&
      error.response.data.code === 'token_not_valid' &&
      !originalRequest.retry
    ) {
      originalRequest.retry = true

      const refreshToken = store.get('refreshToken')
      const url = `${process.env.REACT_APP_API_URL}auth/jwt/refresh/`

      await axios
        .post(url, {
          refresh: refreshToken,
        })
        .then(response => {
          const accessToken = response.data.access
          if (accessToken) {
            store.set('accessToken', accessToken)
            axios.defaults.headers.Authorization = `Bearer ${accessToken}`
          }
        })
      return apiClient(originalRequest)
    }
    if (!originalRequest.retry) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { data } = error.response
        const msg = checkMessage(data)
        notification.warning({
          message: msg, // data.current_password ?? data.new_password ?? data.detail.email ?? data.detail,
        })

        /*  console.log("error.response.data");
        console.log(error.response.data);
        console.log("error.response.status");
        console.log(error.response.status);
        console.log("error.response.headers");
        console.log(error.response.headers); */
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        const { request } = error
        notification.warning({
          message: request,
        })
        // console.log("error.request");
        // console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        const { message } = error
        notification.error({
          message,
        })
        /*
        console.log("error.message");
        console.log('Error', error.message);
        */
      }
    }
    return Promise.reject(error)
  },
)

const checkMessage = data => {
  if ('detail' in data) {
    return data.detail.email ?? data.detail ?? data.detail.name ?? 'Request failed'
  }
  return data.current_password ?? data.new_password ?? data.email ?? data.name ?? 'Request failed'
}
/*
apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    notification.warning({
      message: data.detail,
    })
  }
})
*/
export default apiClient
