import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import products from './product/sagas'
import account from './account/sagas'
import lq from './lq/sagas'
import users from './users/sagas'
import rfm from './rfm/sagas'
import cocAccountValidation from './coc-account-validation/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    products(),
    account(),
    lq(),
    users(),
    rfm(),
    cocAccountValidation(),
  ])
}
