import { all, takeEvery, put, call } from 'redux-saga/effects'

import { notification } from 'antd'
import {
  getProducts,
  getRFMresults,
  getCanRunRFM,
  getAccountProducts,
  postAccountProducts,
  putAccountProducts,
  deleteAccountProducts,
} from 'services/products'
import actions from './actions'

export function* GET_DATA() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const productData = yield call(getProducts)
  if (productData) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productData,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_RFM_RESULTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const rfmResults = yield call(getRFMresults)
  if (rfmResults) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        rfmResults,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_CAN_RUN_RFM() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const data = yield call(getCanRunRFM)
  if (data && data.allowed) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        rfmAllowed: true,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_ACCOUNTPRODUCTS({ payload: { accountId } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const accountProducts = yield call(getAccountProducts, accountId)
  if (accountProducts) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountProducts,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* ADD_ACCOUNTPRODUCTS({ payload }) {
  const { accountId, product, expiresAt, isActive, orderId, limit } = payload
  console.log(payload, 'sagas')
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const accountProducts = yield call(
    postAccountProducts,
    accountId,
    product,
    expiresAt,
    isActive,
    orderId,
    limit,
  )
  if (accountProducts) {
    yield put({
      type: actions.ADD_STATE_ACCOUNTPRODUCTS,
      payload: {
        accountProducts,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Product Added',
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_ACCOUNTPRODUCTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(deleteAccountProducts, payload)

  if (result) {
    yield put({
      type: actions.UPDATE_DELETE_ACCOUNTPRODUCTS,
      payload: {
        id: payload,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Product Deleted',
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* EDIT_ACCOUNTPRODUCTS({ payload }) {
  const { id, product, expiresAt, isActive, orderId, limit } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const accountProducts = yield call(
    putAccountProducts,
    id,
    product,
    expiresAt,
    isActive,
    orderId,
    limit,
  )
  if (accountProducts) {
    yield put({
      type: actions.UPDATE_STATE_ACCOUNTPRODUCTS,
      payload: {
        accountProducts,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Product updated',
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.GET_RFM_RESULTS, GET_RFM_RESULTS),
    takeEvery(actions.GET_CAN_RUN_RFM, GET_CAN_RUN_RFM),
    takeEvery(actions.GET_ACCOUNTPRODUCTS, GET_ACCOUNTPRODUCTS),
    takeEvery(actions.ADD_ACCOUNTPRODUCTS, ADD_ACCOUNTPRODUCTS),
    takeEvery(actions.DELETE_ACCOUNTPRODUCTS, DELETE_ACCOUNTPRODUCTS),
    takeEvery(actions.EDIT_ACCOUNTPRODUCTS, EDIT_ACCOUNTPRODUCTS),
  ])
}
