import apiClient from 'services/axios'

export async function getAccounts() {
  return apiClient
    .get('/api/account/')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function getAccount(accountId) {
  return apiClient
    .get(`/api/account/${accountId}/`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function deleteAccount(accountId) {
  return apiClient
    .delete(`/api/account/${accountId}/`)
    .then(response => {
      if (response) {
        return true
      }
      return false
    })
    .catch(() => {
      return false
    })
}

// eslint-disable-next-line camelcase
export async function editAccount(id, name, email, IDI_client) {
  return apiClient
    .patch(`/api/account/${id}/`, {
      id,
      name,
      email,
      IDI_client,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function createAccount(name, email, IDIclient) {
  return apiClient
    .post(`/api/account/`, {
      name,
      email,
      IDI_client: IDIclient,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function getUsers(accountId = null) {
  const url = accountId == null ? '/api/accountuser/' : `/api/accountuser/${accountId}`
  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function removeUser(id) {
  return apiClient
    .delete(`/api/accountuser/${id}`)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function createUser(email, password, name, accountId = -1) {
  return apiClient
    .post('/api/accountuser/', {
      email,
      password,
      name,
      accountId,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => {
      return false
    })
}
