import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import store from 'store'
import { getData, getTableData, getTableDataStats, setCompanyClassification } from 'services/rfm'

import actions from './actions'

export function* SET_CURRENT_PAGE({ payload: { currentPage } }) {
  yield put({
    type: 'rfm/SET_STATE',
    payload: {
      currentPage,
    },
  })
}

export function* SET_SUB_PAGE({ payload: { currentSubPage } }) {
  yield put({
    type: 'rfm/SET_STATE',
    payload: {
      currentSubPage,
    },
  })
}

export function* CHANGE_TABLE_SETTING({ payload: { setting, value } }) {
  // yield store.set(`app.lq_settings.${setting}`, value)
  yield put({
    type: 'rfm/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* GET_RECENCY_FREQUENCY() {
  yield put({
    type: 'rfm/SET_STATE',
    payload: {
      recencyFrequency: {
        data: [],
        loading: true,
      },
    },
  })
  const results = yield call(getData, 'recency_frequency')
  if (results) {
    yield put({
      type: 'rfm/SET_STATE',
      payload: {
        recencyFrequency: {
          data: results,
          loading: false,
        },
      },
    })
  }
}

export function* GET_MIGRATION() {
  yield put({
    type: 'rfm/SET_STATE',
    payload: {
      migration: {
        data: [],
        loading: true,
      },
    },
  })
  const results = yield call(getData, 'migration')
  if (results) {
    yield put({
      type: 'rfm/SET_STATE',
      payload: {
        migration: {
          data: results,
          loading: false,
        },
      },
    })
  }
}

export function* GET_DATA({ payload: { variable, type } }) {
  yield put({
    type: 'rfm/SET_STATE',
    payload: {
      [variable]: {
        data: [],
        loading: true,
      },
    },
  })
  const results = yield call(getData, type)
  if (results) {
    yield put({
      type: 'rfm/SET_STATE',
      payload: {
        [variable]: {
          data: results,
          loading: false,
        },
      },
    })
  }
}

// Action Table
export function* GET_ACTION_TABLE_DATA({ order, offset, limit, filter = '' }) {
  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getTableData, order, offset, limit, filter)
  if (data) {
    yield put({
      type: actions.SET_ACTION_TABLE_STATE,
      payload: { data },
    })
  }

  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: { loading: false },
  })
}

export function* GET_ACTION_TABLE_DATA_STATS({ filter = '' }) {
  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: {
      loading: true,
    },
  })
  const dataFilters = yield call(getTableDataStats, filter)
  if (dataFilters) {
    yield put({
      type: actions.SET_ACTION_TABLE_STATE,
      payload: {
        dataFilters,
      },
    })
  }

  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_ACTION_TABLE_SETTING({ payload: { setting, value } }) {
  // yield store.set(`app.rfm_actiontable_settings.${setting}`, value)
  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: {
      [setting]: value,
    },
  })
}

export function* CHANGE_CLASSIFICATION({ payload }) {
  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: {
      loading: false,
    },
  })
  const { id, classification } = payload
  const response = yield call(setCompanyClassification, id, classification)
  if (response) {
    yield put({
      type: actions.CHANGE_ACTION_TABLE_CLASSIFICATION,
      payload: {
        id: response.id,
        action: response.action,
      },
    })
    notification.success({
      message: 'Success',
      description: 'Action is updated',
    })
  }
  yield put({
    type: actions.SET_ACTION_TABLE_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_MIGRATION, GET_MIGRATION),
    takeEvery(actions.SET_CURRENT_PAGE, SET_CURRENT_PAGE),
    takeEvery(actions.SET_SUB_PAGE, SET_SUB_PAGE),
    takeEvery(actions.CHANGE_TABLE_SETTING, CHANGE_TABLE_SETTING),
    takeEvery(actions.GET_DATA, GET_DATA),

    takeEvery(actions.GET_RECENCY_FREQUENCY, GET_RECENCY_FREQUENCY),
    takeEvery(actions.GET_ACTION_TABLE_DATA, GET_ACTION_TABLE_DATA),
    takeEvery(actions.GET_ACTION_TABLE_DATA_STATS, GET_ACTION_TABLE_DATA_STATS),
    takeEvery(actions.CHANGE_ACTION_TABLE_SETTING, CHANGE_ACTION_TABLE_SETTING),
    takeEvery(actions.CHANGE_CLASSIFICATION, CHANGE_CLASSIFICATION),
  ])
}
