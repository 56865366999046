import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import products from './product/reducers'
import account from './account/reducers'
import { lqReducer as lq, ldSettingsReducer as lqSettings } from './lq/reducers'
import users from './users/reducers'
import { reducer as rfm, reducerActionTable as rfmActionTable } from './rfm/reducers'
import {
  reducer as cocAccountValidation,
  settingsReducer as cocAccountValidationSettings,
} from './coc-account-validation/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    products,
    account,
    lq,
    lqSettings,
    users,
    rfm,
    rfmActionTable,
    cocAccountValidation,
    cocAccountValidationSettings,
  })
