import apiClient from 'services/axios'

export async function getData(type) {
  return apiClient
    .get(`/api/rfm/dashboard/${type}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTableData(order, offset, limit, filter) {
  return apiClient
    .get(`/api/rfm/get_table_data/${order}/${offset}/${limit}/`, {
      params: filter,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTableDataStats(filter) {
  return apiClient
    .get(`/api/rfm/get_table_data_stats/`, {
      params: filter,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setCompanyClassification(id, classification) {
  return apiClient
    .post('/api/rfm/set_company_classification/', {
      id,
      classification,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}
