const actions = {
  SET_STATE: 'account/SET_STATE',
  UPDATE_STATE_ACCOUNTS: 'account/UPDATE_STATE_ACCOUNTS',
  GET_USERS: 'account/GET_USERS',
  REMOVE_USER: 'account/REMOVE_USER',
  CREATE_USER: 'account/CREATE_USER',
  GET_ACCOUNTS: 'account/GET_ACCOUNTS',
  GET_ACCOUNT: 'account/GET_ACCOUNT',
  EDIT_ACCOUNT: 'account/EDIT_ACCOUNT',
  CREATE_ACCOUNT: 'account/CREATE_ACCOUNT',
  DELETE_ACCOUNT: 'account/DELETE_ACCOUNT',
}

export default actions
