import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import {
  getCanRun,
  getSummary,
  getTextSummary,
  getAccountDetails,
  getTableDataStats,
  getHeatmap,
  getCleanedDetails,
  getCreditDetails,
  getMarketDetails,
  getInputStatuses,
  getUploadDetails,
  getProvincemap,
  getMarketCoverage,
  getCounter,
  setCreditDetails,
  getBoughtCreditDetails,
  getBar,
  getPie,
  getDropDown,
} from 'services/coc-account-validation'

import actions from './actions'

export function* GET_DROPDOWN_VALUES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const data = yield call(getDropDown)
  if (data) {
    if (data === [])
      yield put({
        type: actions.SET_STATE,
        payload: {
          file: data,
          dashboard: false,
        },
      })
    else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          file: data,
          dashboard: true,
        },
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SET_DROPDOWN_VALUES({ payload: file }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fileState: file,
    },
  })
}

export function* GET_CAN_RUN() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getCanRun)
  if (data && data.allowed) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cocAllowed: true,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_COUNTER({ payload }) {
  const { counter } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const data = yield call(getCounter, counter)
  if (data) {
    if (counter === 'coc_data')
      yield put({
        type: actions.SET_STATE,
        payload: {
          counterCocData: data,
        },
      })
    else if (counter === 'credit_score')
      yield put({
        type: actions.SET_STATE,
        payload: {
          counterCreditScore: data,
        },
      })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_INPUT_STATUSES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const inputStatuses = yield call(getInputStatuses)
  if (inputStatuses) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        inputStatuses,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_UPLOAD_DETAILS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const uploadDetails = yield call(getUploadDetails)
  if (uploadDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        uploadDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_SUMMARY({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const summary = yield call(getSummary, file)
  if (summary) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        summary,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_TEXTSUMMARY({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const textSummary = yield call(getTextSummary, file)
  if (textSummary) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        textSummary,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_MARKETPIE({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const marketPie = yield call(getPie, file)
  if (marketPie) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        marketPie,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_MARKETBAR({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const marketBar = yield call(getBar, file)
  if (marketBar) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        marketBar,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_MARKETDETAILS({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const marketDetails = yield call(getMarketDetails, file)
  if (marketDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        marketDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_HEATMAP({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const heatmap = yield call(getHeatmap, file)
  if (heatmap) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        heatmap,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_MARKETCOVERAGE({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const marketCoverage = yield call(getMarketCoverage, file)
  if (marketCoverage) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        marketCoverage,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_PROVINCEMAP({ payload: { file } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const provinceMap = yield call(getProvincemap, file)
  if (provinceMap) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        provinceMap,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_CLEANED_DETAILS({ file }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const cleanedDetails = yield call(getCleanedDetails, file)
  if (cleanedDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cleanedDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_CREDIT_DETAILS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const creditSelectDetails = yield call(getCreditDetails)
  if (creditSelectDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        creditSelectDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* BOUGHT_CREDIT_DETAILS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const boughtCreditDetails = yield call(getBoughtCreditDetails)
  if (boughtCreditDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        boughtCreditDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_ACCOUNTDETAILS({ order, file }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      dataLoading: true,
    },
  })
  const accountDetails = yield call(getAccountDetails, order, file)
  if (accountDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      dataLoading: false,
    },
  })
}

export function* GET_TABLEDATA_STATS({ payload }) {
  const { fields, filter = '' } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const dataFilters = yield call(getTableDataStats, fields, filter)
  if (dataFilters) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        dataFilters,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SET_CREDIT_DETAILS({ payload }) {
  const keys = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const creditDetails = yield call(setCreditDetails, keys)
  if (creditDetails) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        creditDetails,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.cocAccountValidation_settings.${setting}`, value)
  yield put({
    type: actions.SET_STATE_SETTINGS,
    payload: {
      [setting]: value,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DROPDOWN_VALUES, GET_DROPDOWN_VALUES),
    takeEvery(actions.SET_DROPDOWN_VALUES, SET_DROPDOWN_VALUES),
    takeEvery(actions.GET_CAN_RUN, GET_CAN_RUN),
    takeEvery(actions.GET_COUNTER, GET_COUNTER),
    takeEvery(actions.GET_INPUT_STATUSES, GET_INPUT_STATUSES),
    takeEvery(actions.GET_UPLOAD_DETAILS, GET_UPLOAD_DETAILS),
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    takeEvery(actions.GET_TEXTSUMMARY, GET_TEXTSUMMARY),
    takeEvery(actions.GET_MARKETDETAILS, GET_MARKETDETAILS),
    takeEvery(actions.GET_MARKETCOVERAGE, GET_MARKETCOVERAGE),
    takeEvery(actions.GET_HEATMAP, GET_HEATMAP),
    takeEvery(actions.GET_PROVINCEMAP, GET_PROVINCEMAP),
    takeEvery(actions.GET_ACCOUNTDETAILS, GET_ACCOUNTDETAILS),
    takeEvery(actions.GET_TABLEDATA_STATS, GET_TABLEDATA_STATS),
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.GET_CLEANED_DETAILS, GET_CLEANED_DETAILS),
    takeEvery(actions.GET_CREDIT_DETAILS, GET_CREDIT_DETAILS),
    takeEvery(actions.SET_CREDIT_DETAILS, SET_CREDIT_DETAILS),
    takeEvery(actions.BOUGHT_CREDIT_DETAILS, BOUGHT_CREDIT_DETAILS),
    takeEvery(actions.GET_MARKETBAR, GET_MARKETBAR),
    takeEvery(actions.GET_MARKETPIE, GET_MARKETPIE),
  ])
}
