import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { getAll, get, update } from 'services/users'
import actions from './actions'

export function* GET_USERS() {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(getAll)
  if (response) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        allUsers: response,
      },
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_USER({ payload }) {
  const { userId } = payload
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(get, userId)
  if (response) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        selectedUser: response,
      },
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EDIT_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { id, name, email, isAdmin, isStaff, isSuperuser } = payload
  const response = yield call(update, id, name, email, isAdmin, isStaff, isSuperuser)
  if (response) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        selectedUser: response,
      },
    })
    notification.success({
      message: 'Sucess',
      description: 'User updated',
    })
  }

  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USERS, GET_USERS),
    takeEvery(actions.GET_USER, GET_USER),
    takeEvery(actions.EDIT_USER, EDIT_USER),
  ])
}
