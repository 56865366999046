import React from 'react'
import { Table } from 'antd'
import { Button } from 'reactstrap'
import { DownloadOutlined } from '@ant-design/icons'
import data from './data.json'
import style from './style.module.scss'

class SampleTables extends React.Component {
  render() {
    const columns = [
      {
        title: 'Company_Id',
        dataIndex: 'company_id',
        key: 'company_id',
      },
      {
        title: 'Company_Name',
        dataIndex: 'company_name',
        key: 'company_name',
      },
      {
        title: 'Order date',
        dataIndex: 'order_date',
        key: 'order_date',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
    ]

    return (
      <div className={style.tableStyle}>
        <div className={`${style.kit__utils__table} mb-0 card`}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
        <div>
          <br />
          <Button
            className="ant-btn-primary"
            target="_blank"
            href="/resources/files/RFM-Sample-data.xlsx"
            icon={<DownloadOutlined />}
            download
          >
            Sample Data
          </Button>
        </div>
      </div>
    )
  }
}

export default SampleTables
